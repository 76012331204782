import { createRouter, createWebHistory } from 'vue-router'
import { isMobile } from '@/utils/index.js'
const isMobiles = isMobile()
const routesMobile = [
  {
    path: '/',
    name: 'index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/home_pc.vue')
  }
  
]

// const routes = isMobiles ? routesMobile : routesPc
const routes = isMobiles ? routesMobile : routesMobile
const router = createRouter({
  history: createWebHistory("/"),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  }
})
router.beforeEach((to, from, next) => {
  next();
});

router.afterEach(() => {
});
export default router
