import { createI18n } from 'vue-i18n'
import zh from './zh.js'
import en from './en.js'
const messages = {
    en,
    zh,
}

const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('defaultLang') || 'zh',
    fallbackLocale: 'zh',
    silentTranslationWarn: true,
    messages,
})
export const translate = (key) => {
    if (!key) {
        return ''
    }
    return i18n.global.t(key)
}

export default i18n