<template>
  <i v-if="iconFileName.indexOf('el-icon-') === 0" :class="iconFileName" />
  <!-- eslint-disable-next-line vue/no-deprecated-dollar-listeners-api -->
  <svg v-else class="svg-icon" aria-hidden="true" v-bind="$attrs">
    <use :xlink:href="`#icon-${iconFileName}`" />
  </svg>
</template>
  
  <script>
  export default {
    name: 'SvgIcon',
    props: {
      iconFileName: {
        type: String,
        required: true
      }
    }
  }
  </script>
  
  <style scoped>
  .svg-icon {
    width: 14px;
    height: 14px;
    overflow: hidden;
    /* vertical-align: -0.15em; */
    fill: currentColor;
  }
  </style>
  
  