import 'babel-polyfill';
import 'lib-flexible'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia';
import piniaPluginPersist from 'pinia-plugin-persist';
import { VueQueryPlugin } from '@tanstack/vue-query'
import i18n from "@/i18n/index.js";
const pinia = createPinia();
pinia.use(piniaPluginPersist);
//引入整个icons,
import Vant, { Locale, Lazyload,Popup } from 'vant'
import 'vant/lib/index.css'
import './vant-variables.less'
import './assets/css/global.less'
import './assets/font/fonts.css'
import lang from 'vant/lib/locale/lang/en-US'
import './assets/css/coin-icon.css'
// 注册时可以配置额外的选项
Locale.use('en-US', lang)
import SvgIcon from '@/components/svgIcon/svgIcon.vue'
const req = require.context('@/assets/icons/svg', false, /\.svg$/)
const requireAll = requireContext => {
  // requireContext.keys()数据：svg文件夹下面的
  requireContext.keys().map(requireContext)
}

requireAll(req)

const app = createApp(App);
app.use(pinia)
app.use(VueQueryPlugin)
app.component('SvgIcon', SvgIcon)
app.use(Lazyload, {
  lazyComponent: true,
})
app.use(Vant)
app.use(router)
app.use(i18n)
app.mount('#app')
app.use(Popup);