export default {
    "qwzsl": "全网总算力",
    "qwmrcc": "全网每日产出",
    "grsl": "个人算力",
    "zrcc": "昨日产出",
    "zcc": "总产出",
    "balance": "余额",
    "tb": "提币",
    "cygz": "参与共振",
    "cyje": "参与金额",
    "yqrdz": "邀请人地址",
    "srje-100": "输入金额（100的整数倍）",
    "qbdzsl": "钱包地址数量",
    "zsl": "总算力",
    "sdz": "首地址",
    "yqlj": "邀请链接",
    "fzyqlj": "复制邀请链接",
    "zyq": "卓越区",
    "wdq": "稳定区",
}